// https://firebase.google.com/docs/auth/web/google-signin?hl=zh-cn
import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'
import { createPortal } from 'react-dom'
import { getFirebaseApp } from 'utils/firebase'
import firebase from 'firebase'
import Loading from 'components/loading'
import router from 'next/router'
interface LoginModalProps {
  allowClose?: boolean
  returnUrl?: string
  onClose: () => void
}

const LoginModal: React.FC<LoginModalProps> = ({
  allowClose,
  returnUrl,
  onClose,
}) => {
  const [visiable, setVisiable] = useState(true)
  // const { setUser } = useContext(AppContext)

  // async function login(platformResult, platform) {
  //   const { user } = platformResult
  //   if (user === null) {
  //     throw Error('User Not Find!')
  //   }
  //   const tokenData = await user.getIdTokenResult()
  //   const data = {
  //     platform,
  //     uid: user.uid,
  //     token: tokenData.token,
  //   }
  //   const rep = await api.login.signin(data)
  //   return rep
  // }

  function signGoogle() {
    Loading.show()
    const provider = new firebase.auth.GoogleAuthProvider()
    getFirebaseApp()
    setTimeout(() => {
      Loading.hide()
    }, 2000)
    firebase.auth().signInWithRedirect(provider)
    // firebaseApp
    //   .auth()
    //   .signInWithPopup(provider)
    //   .then((result) => {
    //     login(result, 'google')
    //       .then((data) => {
    //         loginSuccess(data)
    //       })
    //       .catch((e) => {
    //         loginFail(e)
    //       })
    //   })
    //   .catch((error) => {
    //     loginFail(error)
    //   })
  }

  function signFacebook() {
    Loading.show()
    const provider = new firebase.auth.FacebookAuthProvider()
    provider.setCustomParameters({
      auth_type: 'rerequest',
      display: 'popup',
    })
    getFirebaseApp()
    setTimeout(() => {
      Loading.hide()
    }, 2000)
    firebase.auth().signInWithRedirect(provider)
    // firebaseApp
    //   .auth()
    //   .signInWithPopup(provider)
    //   .then((result) => {
    //     login(result, 'facebook')
    //       .then((data) => {
    //         loginSuccess(data)
    //       })
    //       .catch((e) => {
    //         loginFail(e)
    //       })
    //   })
    //   .catch((error) => {
    //     loginFail(error)
    //   })
  }

  // function signApple() {
  //   Loading.show()
  //   const provider = new firebase.auth.OAuthProvider('apple.com')
  //   getFirebaseApp(location.host)
  //   firebase.auth().signInWithRedirect(provider)
  //   // firebaseApp
  //   //   .auth()
  //   //   .signInWithPopup(provider)
  //   //   .then((result) => {
  //   //     login(result, 'apple')
  //   //       .then((data) => {
  //   //         loginSuccess(data)
  //   //       })
  //   //       .catch((e) => {
  //   //         loginFail(e)
  //   //       })
  //   //   })
  //   //   .catch((error) => {
  //   //     loginFail(error)
  //   //   })
  // }

  // function loginSuccess(data) {
  //   // const sensors: Window['sensors'] | undefined = window?.sensors
  //   const profile = {
  //     id: data.id,
  //     name: data.name,
  //     avatar: data.avatar,
  //     token: data.token,
  //   }
  //   setUserInfoCache(profile)
  //   setUser(profile)
  //   sensors.login(profile.id)
  //   api.login.syncPosition()
  //   localStorage.setItem(
  //     'LAST_SYNCPOSITION_TIME',
  //     new Date().getTime().toString()
  //   )
  //   Loading.hide()
  //   close()
  //   onSuccess?.(data)
  // }

  // function loginFail(e) {
  //   alert('login fail:' + e)
  //   Loading.hide()
  //   close()
  // }

  // function close() {
  //   setVisiable(false)
  //   onClose?.()
  // }

  return (
    <>
      {visiable
        ? createPortal(
            <div
              className="fixed top-0 left-0 right-0 bottom-0 m-auto w-full h-full lg:w-[570px] lg:h-[600px] z-[999]"
              id="J_LOGINMODAL"
            >
              <div className="fixed w-full h-full left-0 top-0 bg-black bg-opacity-30 z-10"></div>
              <div
                className="absolute top-8 left-4 lg:top-4 lg:right-4 lg:left-auto cursor-pointer z-30"
                onClick={() => {
                  setVisiable(false)
                  onClose()
                  if (!allowClose) {
                    returnUrl.match('stories')
                      ? router.back()
                      : router.replace('/')
                  }
                }}
              >
                <svg
                  viewBox="0 0 1045 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                >
                  <path
                    d="M282.517333 213.376l-45.354666 45.162667L489.472 512 237.162667 765.461333l45.354666 45.162667L534.613333 557.354667l252.096 253.269333 45.354667-45.162667-252.288-253.44 252.288-253.482666-45.354667-45.162667L534.613333 466.624l-252.096-253.226667z"
                    fill="#333333"
                  ></path>
                </svg>
              </div>
              <div className="relative flex flex-col justify-center w-full h-full px-8 lg:p-32 lg:rounded bg-white bg-login bg-top-center bg-cover z-20">
                <div className="my-2">
                  <img src="/images/logo-readink.png" width={150} alt="" />
                </div>
                <div className="text-2xl font-bold">
                  Sign in to Unlock <br />
                  more Wonderful Stories <br />
                  in Readink
                </div>
                <div className="py-6">
                  <div
                    className="relative flex flex-col items-center justify-center h-12 my-4 rounded-full border border-solid border-[#262626] cursor-pointer"
                    onClick={() => {
                      signGoogle()
                    }}
                  >
                    <img
                      src="/images/icon-google.png"
                      className="absolute top-1/2 transform -translate-y-1/2 left-6 w-6 h-6"
                      alt=""
                    />
                    Continue with Google
                  </div>
                  <div
                    className="relative flex flex-col items-center justify-center h-12 my-4 rounded-full border border-solid border-[#262626] cursor-pointer"
                    onClick={() => {
                      signFacebook()
                    }}
                  >
                    <img
                      src="/images/icon-facebook.png"
                      className="absolute top-1/2 transform -translate-y-1/2 left-6 w-6 h-6"
                      alt=""
                    />
                    Continue with Facebook
                  </div>
                </div>
                <div className="absolute w-full py-3 bottom-4 left-0 text-center text-gray-400 text-xs">
                  By continuing logging in, you are accepting the
                  <br />
                  <a className="underline" href="/terms-of-service.html">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a className="underline" href="/privacy-policy.html">
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>,
            document.body
          )
        : null}
    </>
  )
}

export default {
  show: ({
    allowClose = true,
    returnUrl = '/',
  }: {
    allowClose?: boolean
    returnUrl?: string
    onSuccess?: (data) => void
  } = {}) => {
    if (!document.querySelector('#J_LOGINMODAL')) {
      const modalRoot = document.createElement('div')
      document.body.appendChild(modalRoot)
      const onClose = () => {
        document.body.removeChild(modalRoot)
      }
      createRoot(modalRoot).render(
        <LoginModal
          allowClose={allowClose}
          returnUrl={returnUrl}
          onClose={onClose}
        />
      )
    }
  },
  hide: () => {
    const loginModal = document.querySelector('#J_LOGINMODAL')
    loginModal && document.body.removeChild(loginModal)
  },
}
