import Layout from 'components/layout'
import { useState } from 'react'
import api from 'services/api'
import { setUserInfoCache } from 'utils/user-info'
import { GetServerSideProps } from 'next'
import Login from 'components/login'
import Loading from 'components/loading'
import { useAsyncEffect } from 'ahooks'
import { getFirebaseApp } from 'utils/firebase'
import firebase from 'firebase'
import storage from 'services/storage'
import { clientPut } from 'lib/fbq'
import { pba } from 'lib/af'

const Index = ({ returnUrl }: { returnUrl: string }): JSX.Element => {
  const [result, setResult] = useState(null)
  const getLoginResult = async () => {
    try {
      await getFirebaseApp()
      firebase
        .auth()
        .getRedirectResult()
        .then((result) => {
          // if (result.credential) {
          //   /** @type {firebase.auth.OAuthCredential} */
          //   var credential = result.credential
          //   // This gives you a Google Access Token. You can use it to access the Google API.
          //   var token = credential.accessToken
          //   // ...
          // }
          // The signed-in user info.
          // const user = result.user
          // IdP data available in result.additionalUserInfo.profile.
          // ...
          // eslint-disable-next-line
          console.log(result)
          if (result?.user) {
            setResult(result)
          } else {
            Loading.hide()
          }
        })
        .catch((error) => {
          // // Handle Errors here.
          // var errorCode = error.code
          // var errorMessage = error.message
          // // The email of the user's account used.
          // var email = error.email
          // // The firebase.auth.AuthCredential type that was used.
          // var credential = error.credential
          // ...
          // eslint-disable-next-line
          console.log(error)
          Loading.hide()
        })
    } catch (error) {
      // alert(JSON.stringify(error))
    }
  }

  // const getLoginResult = async () => {
  //   try {
  //     const firebaseApp = getFirebaseApp()
  //     const result = await firebaseApp
  //       .auth()
  //       .getRedirectResult()
  //       .then((result) => {
  //         if (result.credential) {
  //           /** @type {firebase.auth.OAuthCredential} */
  //           var credential = result.credential
  //           // This gives you a Google Access Token. You can use it to access the Google API.
  //           var token = credential.accessToken
  //           // ...
  //         }
  //         // The signed-in user info.
  //         var user = result.user
  //         // IdP data available in result.additionalUserInfo.profile.
  //         // ...
  //         if (user) {
  //           login({
  //             user,
  //           })
  //         }
  //         Loading.hide()
  //       })
  //       .catch((error) => {
  //         // Handle Errors here.
  //         var errorCode = error.code
  //         var errorMessage = error.message
  //         // The email of the user's account used.
  //         var email = error.email
  //         // The firebase.auth.AuthCredential type that was used.
  //         var credential = error.credential
  //         // ...
  //         Loading.hide()
  //       })
  //     return result
  //   } catch (error) {
  //     alert(JSON.stringify(error))
  //   }
  // }

  async function login(result) {
    const { additionalUserInfo, user } = result
    if (user === null) {
      throw Error('User Not Find!')
    }
    const platform = additionalUserInfo.providerId.replace('.com', '')
    const data = {
      platform,
      uid: user.uid,
      token: user.Aa,
    }
    const rep = await api.login.signin(data)
    return rep
  }

  useAsyncEffect(async () => {
    Login.show({ allowClose: false, returnUrl })
    Loading.show()
    getLoginResult()
    // const result = await getLoginResult()
    // // eslint-disable-next-line
    // console.log(result)
    // if (result?.user) {
    //   setResult(result)
    // } else {
    //   Loading.hide()
    // }
  }, [])

  useAsyncEffect(async () => {
    if (result?.user) {
      const data = await login(result)
      const profile = {
        id: data.id,
        name: data.name,
        avatar: data.avatar,
        token: data.token,
      }
      setUserInfoCache(profile)
      window?.sensors?.login(profile.id)
      pba('setCustomerUserId', profile.id)
      await api.login.syncPosition()
      if (storage.get('from', true) === 'fb') {
        await clientPut({
          userId: profile.id,
        })
      }
      location.href =
        location.origin + (returnUrl ? decodeURIComponent(returnUrl) : '/')
    }
  }, [result])

  return (
    <Layout showDownloadBtn={false} showFooter={false}>
      {null}
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const userInfo = context.req.cookies['user-info'] || null
  const returnUrl = (context.query['returnUrl'] as string) || '/'
  if (userInfo) {
    return {
      redirect: {
        permanent: true,
        destination: `/`,
      },
    }
  }
  return {
    props: {
      returnUrl,
    },
  }
}

export default Index
